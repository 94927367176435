import { ref } from '@vue/composition-api'
import store from '@/store'
import { formatDateTimeDb } from '@/libs/timezone'

export const useWithdrawFilter = () => {
  const search = ref(null)
  const withdrawStatus = ref('')
  const withdrawStatusOptions = ref([
    { value: 'approved', text: 'Thành công' },
    { value: 'pending', text: 'Chờ xử lý' },
    { value: 'rejected', text: 'Từ chối' },
  ])
  const mapStatusVariant = status => {
    switch (status) {
      case 'approved':
        return 'success'
      case 'pending':
        return 'warning'
      case 'rejected':
        return 'danger'
      default:
        return 'warning'
    }
  }

  const mapStatus = status => {
    switch (status) {
      case 'approved':
        return 'Thành công'
      case 'pending':
        return 'Chờ xử lý'
      case 'rejected':
        return 'Từ chối'
      default:
        return 'Chờ xử lý'
    }
  }

  return {
    search,
    withdrawStatus,
    withdrawStatusOptions,
    mapStatus,
    mapStatusVariant,
  }
}

export const useListWithdraw = () => {
  const fields = ref([
    '#',
    {
      key: 'merchant.username',
      label: 'tên merchant',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'bank.bank_number', label: 'số tài khoản' },
    { key: 'bank.bank_account', label: 'tên tài khoản' },
    { key: 'bank.bank_master.name', label: 'ngân hàng' },
    {
      key: 'amount',
      label: 'số tiền muốn rút',
      thClass: 'text-center',
      tdClass: 'text-right',
      formatter: value => (value ? value.toLocaleString() : 0),
    },
    {
      key: 'updated_at',
      label: 'ngày cập nhật',
      thClass: 'text-center',
      tdClass: 'text-center',
      formatter: value => formatDateTimeDb(value),
    },
    {
      key: 'status',
      label: 'trạng thái giao dịch',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'actions', label: 'Hành động' },
  ])
  const items = ref([])

  const fetchAllBankWithdraw = (...args) => store.dispatch('withdraw-manager/fetchAllBankWithDraw', ...args)
  const changeStatus = (...args) => store.dispatch('withdraw-manager/changeWithdrawStatus', ...args)

  return {
    fields,
    items,
    fetchAllBankWithdraw,
    changeStatus,
  }
}

export const _ = null
