import { render, staticRenderFns } from "./DetailWithdraw.vue?vue&type=template&id=46ff4467&scoped=true&"
import script from "./DetailWithdraw.vue?vue&type=script&lang=js&"
export * from "./DetailWithdraw.vue?vue&type=script&lang=js&"
import style0 from "./DetailWithdraw.vue?vue&type=style&index=0&id=46ff4467&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ff4467",
  null
  
)

export default component.exports