<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-row>
        <b-col cols="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              placeholder="Tên merchant"
            />
          </b-input-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <v-select
              v-model="withdrawStatus"
              placeholder="Trạng thái"
              label="text"
              :options="withdrawStatusOptions"
              :reduce="item => item.value"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="fetchListBankWithdraw"
          >
            Tìm kiếm
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            small
            :fields="fields"
            :items="items"
            responsive
            class="mt-2"
            show-empty
            bordered
          >
            <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>
            <template #empty>
              <b-row>
                <b-col>
                  <b-alert
                    show
                    variant="info"
                    class="text-center"
                  >
                    Không có dữ liệu
                  </b-alert>
                </b-col>
              </b-row>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="mapStatusVariant(data.value)">
                {{ mapStatus(data.value) }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-img
                  :id="`account-row-${row.item.id}-send-icon`"
                  :src="require('@/assets/images/withdraw/qr-code.svg')"
                  height="18"
                  class="cursor-pointer"
                  @click="detailWithdraw.showWithdrawDetailModal(row.item)"
                />
                <b-tooltip
                  placement="left"
                  title="Xem chi tiết"
                  :target="`account-row-${row.item.id}-send-icon`"
                />
                <b-img
                  :id="`account-row-${row.item.id}-preview-icon`"
                  :src="require('@/assets/images/withdraw/check.svg')"
                  height="22"
                  :class="row.item.status === 'pending' ? 'mx-1 cursor-pointer' : 'mx-1'"
                  @click="handlerApprove(row.item)"
                />
                <b-tooltip
                  v-if="row.item.status === 'pending'"
                  placement="left"
                  title="Đã chuyển"
                  :target="`account-row-${row.item.id}-preview-icon`"
                />

                <b-img
                  :id="`account-row-${row.item.id}-stop-icon`"
                  :src="require('@/assets/images/withdraw/close.svg')"
                  height="24"
                  :class="row.item.status === 'pending' ? 'cursor-pointer' : ''"
                  @click="handlerReject(row.item)"
                />
                <b-tooltip
                  v-if="row.item.status === 'pending'"
                  placement="left"
                  title="Từ chối"
                  :target="`account-row-${row.item.id}-stop-icon`"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <detail-withdraw ref="detailWithdraw" />
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BTable,
  BFormGroup,
  BAlert,
  BBadge,
  BImg,
  BTooltip,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import DetailWithdraw from '@/views/withdraw/DetailWithdraw.vue'
import Swal from 'sweetalert2'
import { useListWithdraw, useWithdrawFilter } from './useWithdraw'

export default {
  name: 'ListWithdraw',
  components: {
    DetailWithdraw,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BTable,
    BFormGroup,
    BAlert,
    BBadge,
    BImg,
    BTooltip,
    BOverlay,
  },
  setup() {
    const detailWithdraw = ref(null)
    const isLoading = ref(false)
    const {
      search,
      withdrawStatus,
      withdrawStatusOptions,
      mapStatus,
      mapStatusVariant,
    } = useWithdrawFilter()
    const {
      fields,
      items,
      fetchAllBankWithdraw,
      changeStatus,
    } = useListWithdraw()

    const fetchListBankWithdraw = () => {
      fetchAllBankWithdraw({
        merchant_name: search.value,
        status: withdrawStatus.value,
      }).then(res => {
        items.value = res.data
      })
    }

    const handlerReject = item => {
      if (item.status !== 'pending') {
        return
      }
      isLoading.value = true
      changeStatus({
        id: item.id,
        status: 'rejected',
      }).then(() => {
        fetchListBankWithdraw()
        Swal.fire({
          icon: 'success',
          title: 'Thành công',
          text: 'Từ chối rút tiền thành công',
        })
      }).finally(() => {
        isLoading.value = false
      })
    }

    const handlerApprove = item => {
      if (item.status !== 'pending') {
        return
      }
      isLoading.value = true
      changeStatus({
        id: item.id,
        status: 'approved',
      }).then(() => {
        fetchListBankWithdraw()
        Swal.fire({
          icon: 'success',
          title: 'Thành công',
          text: 'Chuyển tiền thành công',
        })
      }).finally(() => {
        isLoading.value = false
      })
    }

    onMounted(() => {
      fetchListBankWithdraw()
    })

    return {
      isLoading,
      detailWithdraw,
      fields,
      items,
      search,
      withdrawStatus,
      withdrawStatusOptions,
      mapStatus,
      mapStatusVariant,
      handlerReject,
      handlerApprove,
      fetchListBankWithdraw,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
