<template>
  <b-modal
    ref="withdrawDetailModal"
    centered
    size="lg"
    title="Chi tiết tài khoản rút tiền"
    :hide-footer="true"
  >
    <b-row cols="2">
      <b-col cols="8">
        <p class="payin-item">
          <span class="payin-item-title">Merchant:</span>
          <span class="payin-item-value">{{ withdrawDetail.merchant.username }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên ngân hàng nhận:</span>
          <span class="payin-item-value">{{ withdrawDetail.bank.bank_master.name }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tài khoản nhận:</span>
          <span class="payin-item-value">{{ withdrawDetail.bank.bank_number }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Tên người nhận:</span>
          <span class="payin-item-value">{{ withdrawDetail.bank.bank_account }}</span>
        </p>
        <p class="payin-item">
          <span class="payin-item-title">Số tiền:</span>
          <span class="payin-item-value">{{ withdrawDetail.amount.toLocaleString() }}</span>
        </p>
      </b-col>

      <b-col cols="4">
        <b-img
          :src="withdrawDetail.qr_code"
          fluid
          rounded
          alt="QR"
          class="p-4 bg-dark"
        />
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'DetailWithdraw',
  components: {
    BModal,
    BRow,
    BCol,
    BImg,
  },
  setup() {
    const withdrawDetailModal = ref(null)
    const withdrawDetail = ref({
      merchant: {
        username: '',
      },
      bank: {
        bank_number: '',
        bank_account: '',
        bank_master: {
          name: '',
        },
      },
      qr_code: '',
      amount: 0,
    })

    const showWithdrawDetailModal = withdraw => {
      withdrawDetailModal.value.show()
      withdrawDetail.value = withdraw
    }

    return {
      withdrawDetailModal,
      withdrawDetail,
      showWithdrawDetailModal,
    }
  },
}
</script>

<style scoped>
.payin-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 70px */

  color: #000000;
}
.payin-item {
  display: flex;
  align-items: center;
}

.payin-item .payin-item-value {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  padding-left: 10px;
  /* or 70px */

  color: #000000;
}
</style>
